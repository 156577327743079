import {
  VesselSearchRequest,
  VesselSearchRequestExtraInfo,
  VesselSearchRequestGeneralInfo,
  VesselSearchRequestOtherInfo,
  VesselSearchRequestPositionInfo,
  VesselSearchRequestUnderDeckInfo,
} from '../server-types';
import {
  ExtrasForm,
  GeneralForm,
  OtherForm,
  PositionsForm,
  RangeFormState,
  UnderDeckForm,
} from '../state/searchVessel';
import moment from 'moment';

interface FOSRange {
  from?: number | string;
  to?: number | string;
}

interface InputFormat {
  general: GeneralForm;
  positions: PositionsForm;
  extras: ExtrasForm;
  underDeck: UnderDeckForm;
  others: OtherForm;
}

export const convertToVesselSearchPayload = (
  input: InputFormat
): VesselSearchRequest => {
  const mapRange = (source: RangeFormState, target: any, key: any) => {
    const createRange = source.from !== '' || source.to !== '';
    if (!createRange) return; // neither from or to was specified

    // Special handling: If (Built), include last year (map to db values)
    let valuesPrepared = { ...source };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== '') targetRange.from = valuesPrepared.from;
    if (valuesPrepared.to !== '') targetRange.to = valuesPrepared.to;
    target[key] = targetRange;
  };

  const mapDateRange = (
    startDate: Date | null,
    endDate: Date | null,
    target: any,
    key: any
  ) => {
    const createRange = startDate !== null || endDate !== null;
    if (!createRange) return; // neither from or to was specified

    let valuesPrepared = { from: startDate, to: endDate };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== null)
      targetRange.from = moment(valuesPrepared.from).format('Y-MM-DD');
    if (valuesPrepared.to !== null)
      targetRange.to = moment(valuesPrepared.to).format('Y-MM-DD');
    target[key] = targetRange;
  };

  const generalPayload: VesselSearchRequestGeneralInfo = {};
  const positionsPayload: VesselSearchRequestPositionInfo = {};
  const extrasPayload: VesselSearchRequestExtraInfo = {};
  const underDeckPayload: VesselSearchRequestUnderDeckInfo = {};
  const othersPayload: VesselSearchRequestOtherInfo = {};

  // --- MAP: GENERAL
  // prepare general (str)
  if (input.general.imoNumber !== '')
    generalPayload.imo = Number(input.general.imoNumber);
  if (input.general.vesselName !== '')
    generalPayload.vesselName = input.general.vesselName;
  if (input.general.vesselExNames !== '')
    generalPayload.vesselExNames = input.general.vesselExNames;
  if (input.general.owner !== '') generalPayload.owner = input.general.owner;
  if (input.general.technical_manager !== '')
    generalPayload.technical_manager = input.general.technical_manager;

  if (input.general.design.length !== 0)
    generalPayload.design = input.general.design.map((el) => {
      return el.value;
    });
  if (input.general.controllingContractor.length !== 0)
    generalPayload.controllingContractor =
      input.general.controllingContractor.map((el) => {
        return el.value;
      });
  if (input.general.shipyard.length !== 0)
    generalPayload.shipYard = input.general.shipyard.map((el) => {
      return el.value;
    });
  if (input.general.shipyardCountry.length !== 0)
    generalPayload.shipYardCountry = input.general.shipyardCountry.map((el) => {
      return el.value;
    });
  if (input.general.region.length !== 0)
    generalPayload.region = input.general.region.map((el) => {
      return el.value;
    });
  // pick first (arr to str) (TODO: make these arr to arr later on)
  if (input.general.shipyardRegion.length !== 0)
    generalPayload.shipYardRegion = input.general.shipyardRegion.map((el) => {
      return el.value;
    });
  if (input.general.mooring.length !== 0)
    generalPayload.mooring = input.general.mooring.map((el) => {
      return el.value;
    });
  if (input.general.DP.length !== 0)
    generalPayload.DP = input.general.DP.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...];

  // pick arr (arr to arr)
  if (input.general.vesselType.length !== 0)
    generalPayload.vesselType = input.general.vesselType.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.vesselSubType.length !== 0)
    generalPayload.vesselSubType = input.general.vesselSubType.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.shipStatuses.length !== 0)
    generalPayload.shipStatus = input.general.shipStatuses.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]

  // boolsc
  if (input.general.subSea !== null)
    generalPayload.vesselSubsea = input.general.subSea === '1';
  if (input.general.northSeaSpot !== null)
    generalPayload.northseaSpot = input.general.northSeaSpot === '1';

  // Ranges
  mapDateRange(
    input.general.builtFrom,
    input.general.builtTo,
    generalPayload,
    'built'
  );
  mapRange(input.general.DWT, generalPayload, 'DWT');
  mapRange(input.general.BHP, generalPayload, 'BHP');
  mapRange(input.general.BP, generalPayload, 'BP');
  mapRange(input.general.deck, generalPayload, 'deck');
  mapRange(input.general.craneSize1, generalPayload, 'crane_size_1');
  mapRange(input.general.craneSize2, generalPayload, 'crane_size_2');
  mapRange(input.general.craneSize3, generalPayload, 'crane_size_3');
  mapRange(input.general.beds, generalPayload, 'beds');
  mapRange(input.general.beam, generalPayload, 'beam');
  mapRange(input.general.maxDraft, generalPayload, 'maxDraft');
  mapRange(input.general.LOA, generalPayload, 'LOA');

  // --- MAP: POSITIONS
  // pick first (arr to str) (TODO: make these arr to arr later on)
  if (input.positions.currentCharterer.length !== 0)
    positionsPayload.currentCharterer = input.positions.currentCharterer.map(
      (el) => {
        return el.value;
      }
    ); // todo: turn this into array!
  mapDateRange(
    input.positions.dateAvailableFrom,
    input.positions.dateAvailableTo,
    positionsPayload,
    'dateAvailable'
  );
  if (input.positions.positionConstraint !== undefined)
    positionsPayload.constraint = input.positions.positionConstraint;

  // --- MAP: EXTRAS
  if (input.extras.ROV.length !== 0)
    extrasPayload.ROV = input.extras.ROV.map((el) => {
      return el.value;
    });
  if (input.extras.fifi.length !== 0)
    extrasPayload.fifi = input.extras.fifi.map((el) => {
      return el.value;
    });
  if (input.extras.oilRec.length !== 0)
    extrasPayload.oilRec = input.extras.oilRec.map((el) => {
      return el.value;
    });
  if (input.extras.iceClass.length !== 0)
    extrasPayload.iceClass = input.extras.iceClass.map((el) => {
      return el.value;
    });
  if (input.extras.FOSIceClass.length !== 0)
    extrasPayload.FOSIceClass = input.extras.FOSIceClass.map((el) => {
      return el.value;
    });
  // bools
  if (input.extras.moonpool !== null)
    extrasPayload.moonpool = input.extras.moonpool === '1';
  if (input.extras.helideck !== null)
    extrasPayload.helideck = input.extras.helideck === '1';
  if (input.extras.SPS !== null) extrasPayload.SPS = input.extras.SPS === '1';
  if (input.extras.standby !== null)
    extrasPayload.standby = input.extras.standby === '1';
  if (input.extras.walkToWork !== null)
    extrasPayload.walkToWork = input.extras.walkToWork === '1';
  if (input.extras.battery !== null)
    extrasPayload.battery = input.extras.battery === '1';
  if (input.extras.dualFuel !== null)
    extrasPayload.dualFuel = input.extras.dualFuel === '1';
  if (input.extras.shorePower !== null)
    extrasPayload.shorePower = input.extras.shorePower === '1';
  // ranges
  mapRange(input.extras.wireCapacity, extrasPayload, 'wireCapacity');
  mapRange(input.extras.AFrame, extrasPayload, 'AFrame');
  mapRange(input.extras.deckStrength, extrasPayload, 'deckStrength');
  mapRange(input.extras.winchPull, extrasPayload, 'winchPull');
  mapRange(input.extras.maxSpeed, extrasPayload, 'maxSpeed');
  mapRange(input.extras.survivors, extrasPayload, 'survivors');
  mapRange(input.extras.passengers, extrasPayload, 'passengers');

  // --- MAP: UNDER DECK
  mapRange(input.underDeck.fuel, underDeckPayload, 'fuel');
  mapRange(input.underDeck.methanol, underDeckPayload, 'methanol');
  mapRange(input.underDeck.bulk, underDeckPayload, 'bulk');
  mapRange(input.underDeck.oilRec, underDeckPayload, 'oilRec');
  mapRange(input.underDeck.brine, underDeckPayload, 'brine');
  mapRange(input.underDeck.mud, underDeckPayload, 'mud');
  mapRange(input.underDeck.baseOil, underDeckPayload, 'baseOil');
  mapRange(
    input.underDeck.specialProducts,
    underDeckPayload,
    'specialProducts'
  );
  mapRange(input.underDeck.ballastWater, underDeckPayload, 'ballastWater');
  mapRange(input.underDeck.MEG, underDeckPayload, 'MEG');
  mapRange(input.underDeck.potWater, underDeckPayload, 'potWater');

  // --- MAP: OTHERS
  if (input.others.hullNumber !== '')
    othersPayload.hullNumber = input.others.hullNumber;
  if (input.others.ERN !== '') othersPayload.ERN = input.others.ERN;
  // pick first (arr to str) (TODO: make these arr to arr later on)
  if (input.others.class.length !== 0)
    othersPayload.class = input.others.class.map((el) => {
      return el.value;
    });
  if (input.others.flag.length !== 0)
    othersPayload.flag = input.others.flag.map((el) => {
      return el.value;
    });
  // arr 2 arr
  if (input.others.dive.length !== 0)
    othersPayload.dive = input.others.dive.map((el) => {
      return el.value;
    });
  if (input.others.tower.length !== 0)
    othersPayload.tower = input.others.tower.map((el) => {
      return el.value;
    });
  if (input.others.underdeckCarousel.length !== 0)
    othersPayload.underdeckCarousel = input.others.underdeckCarousel.map(
      (el) => {
        return el.value;
      }
    );
  // ranges
  mapDateRange(
    input.others.nbOrderDateFrom,
    input.others.nbOrderDateTo,
    othersPayload,
    'nbOrderDate'
  );
  mapRange(input.others.contractPrice, othersPayload, 'contractPrice');
  mapRange(input.others.streamerCapacity, othersPayload, 'streamerCapacity');
  // bool
  if (input.others.laySpread !== null)
    othersPayload.laySpread = input.others.laySpread === '1';

  return {
    general: generalPayload,
    positions: positionsPayload,
    extras: extrasPayload,
    underDeck: underDeckPayload,
    other: othersPayload,
  };
};
