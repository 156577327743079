import { atom, RecoilState } from 'recoil';

//#region TYPES (TODO: Move)
export interface MultiSelectFormState {
  selectedValues: any[];
  textValue: string;
}

export interface MultiSelectOption {
  label: string;
  value: any;
}

export type MultiSelectOptions = MultiSelectOption[];

export interface RangeFormState {
  from: string;
  to: string;
}

export interface PositionConstraintFormState {
  topLeft: {
    lat: number;
    lon: number;
  };
  bottomRight: {
    lat: number;
    lon: number;
  };
}
//#endregion

//#region Form Interfaces
export enum FormPart {
  General,
  Positions,
  Extras,
  UnderDeck,
  Other,
}

export interface GeneralForm {
  imoNumber: string;
  vesselName: string;
  vesselExNames: string;
  owner: string;
  technical_manager: string;
  design: MultiSelectOptions;
  vesselType: MultiSelectOptions;
  vesselSubType: MultiSelectOptions;
  subSea: string | null; // bool (string val)
  northSeaSpot: string | null; // bool (string val),
  controllingContractor: MultiSelectOptions;
  shipStatuses: MultiSelectOptions;
  shipyard: MultiSelectOptions;
  shipyardCountry: MultiSelectOptions;
  shipyardRegion: MultiSelectOptions;
  builtFrom: Date | null;
  builtTo: Date | null;
  DWT: RangeFormState;
  BHP: RangeFormState;
  BP: RangeFormState;
  deck: RangeFormState;
  craneSize1: RangeFormState;
  craneSize2: RangeFormState;
  craneSize3: RangeFormState;
  beds: RangeFormState;
  beam: RangeFormState;
  maxDraft: RangeFormState;
  LOA: RangeFormState;
  DP: MultiSelectOptions;
  mooring: MultiSelectOptions;
  region: MultiSelectOptions;
}

export interface PositionsForm {
  currentCharterer: MultiSelectOptions;
  dateAvailableFrom: Date | null;
  dateAvailableTo: Date | null;
  positionConstraint: PositionConstraintFormState | undefined;
}

export interface ExtrasForm {
  ROV: MultiSelectOptions; // Possible opts: Hangar, No, Mezzdeck, Yes, NULL
  moonpool: string | null; // Possible opts:
  helideck: string | null; // Possible opts:
  wireCapacity: RangeFormState;
  AFrame: RangeFormState;
  deckStrength: RangeFormState;
  winchPull: RangeFormState;
  fifi: MultiSelectOptions; // Possible opts: NULL, 3, 1, 2, No, Prepared, 1+2
  oilRec: MultiSelectOptions; // Y, N, NOFO 2009, NOFO 2005, 1, NULL
  iceClass: MultiSelectOptions;
  FOSIceClass: MultiSelectOptions;
  SPS: string | null;
  maxSpeed: RangeFormState;
  standby: string | null;
  walkToWork: string | null;
  survivors: RangeFormState;
  passengers: RangeFormState;
  battery: string | null;
  dualFuel: string | null;
  shorePower: string | null;
}

export interface UnderDeckForm {
  fuel: RangeFormState;
  methanol: RangeFormState;
  bulk: RangeFormState;
  oilRec: RangeFormState;
  brine: RangeFormState;
  mud: RangeFormState;
  baseOil: RangeFormState;
  specialProducts: RangeFormState;
  ballastWater: RangeFormState;
  MEG: RangeFormState;
  potWater: RangeFormState;
}

export interface OtherForm {
  hullNumber: string;
  class: MultiSelectOptions;
  flag: MultiSelectOptions;
  nbOrderDateFrom: Date | null;
  nbOrderDateTo: Date | null;
  contractPrice: RangeFormState;
  dive: MultiSelectOptions;
  tower: MultiSelectOptions;
  underdeckCarousel: MultiSelectOptions;
  laySpread: string | null; // boolean
  ERN: string;
  streamerCapacity: RangeFormState;
}
//#endregion

//#region States

// --- Default vals ---

export const defaultVesselGeneralForm: GeneralForm = {
  imoNumber: '',
  vesselName: '',
  vesselExNames: '',
  owner: '',
  technical_manager: '',
  design: [],
  vesselType: [],
  vesselSubType: [],
  subSea: null,
  northSeaSpot: null,
  controllingContractor: [],
  shipStatuses: [],
  shipyard: [],
  shipyardCountry: [],
  shipyardRegion: [],
  builtFrom: null,
  builtTo: null,
  DWT: { from: '', to: '' },
  BHP: { from: '', to: '' },
  BP: { from: '', to: '' },
  deck: { from: '', to: '' },
  craneSize1: { from: '', to: '' },
  craneSize2: { from: '', to: '' },
  craneSize3: { from: '', to: '' },
  beds: { from: '', to: '' },
  beam: { from: '', to: '' },
  maxDraft: { from: '', to: '' },
  LOA: { from: '', to: '' },
  DP: [],
  mooring: [],
  region: [],
};

export const defaultVesselPositionsForm: PositionsForm = {
  dateAvailableFrom: null,
  dateAvailableTo: null,
  currentCharterer: [],
  positionConstraint: undefined,
};

export const defaultVesselExtrasForm: ExtrasForm = {
  ROV: [],
  moonpool: null,
  helideck: null,
  wireCapacity: { from: '', to: '' },
  AFrame: { from: '', to: '' },
  deckStrength: { from: '', to: '' },
  winchPull: { from: '', to: '' },
  fifi: [],
  oilRec: [],
  iceClass: [],
  FOSIceClass: [],
  SPS: null,
  maxSpeed: { from: '', to: '' },
  standby: null,
  walkToWork: null,
  survivors: { from: '', to: '' },
  passengers: { from: '', to: '' },
  battery: null,
  dualFuel: null,
  shorePower: null,
};

export const defaultVesselUnderDeckForm: UnderDeckForm = {
  fuel: { from: '', to: '' },
  methanol: { from: '', to: '' },
  bulk: { from: '', to: '' },
  oilRec: { from: '', to: '' },
  brine: { from: '', to: '' },
  mud: { from: '', to: '' },
  baseOil: { from: '', to: '' },
  specialProducts: { from: '', to: '' },
  ballastWater: { from: '', to: '' },
  MEG: { from: '', to: '' },
  potWater: { from: '', to: '' },
};

export const defaultVesselOtherForm: OtherForm = {
  hullNumber: '',
  class: [],
  flag: [],
  nbOrderDateFrom: null,
  nbOrderDateTo: null,
  contractPrice: { from: '', to: '' },
  dive: [],
  tower: [],
  underdeckCarousel: [],
  laySpread: null,
  ERN: '',
  streamerCapacity: { from: '', to: '' },
};

// --- Recoil States ---

export const vesselSearchGeneralForm: RecoilState<GeneralForm> = atom({
  key: 'vesselSearchGeneralForm',
  default: defaultVesselGeneralForm,
});

export const vesselSearchPositionsForm: RecoilState<PositionsForm> = atom({
  key: 'vesselSearchPositionsForm',
  default: defaultVesselPositionsForm,
});

export const vesselSearchExtrasForm: RecoilState<ExtrasForm> = atom({
  key: 'vesselSearchExtrasForm',
  default: defaultVesselExtrasForm,
});

export const vesselSearchUnderDeckForm: RecoilState<UnderDeckForm> = atom({
  key: 'vesselSearchUnderDeckForm',
  default: defaultVesselUnderDeckForm,
});

export const vesselSearchOtherForm: RecoilState<OtherForm> = atom({
  key: 'vesselSearchOtherForm',
  default: defaultVesselOtherForm,
});

export const vesselSearchResults: RecoilState<any> = atom({
  key: 'vesselSearchResults',
  default: [],
});

export const keptVesselSearchResults: RecoilState<any> = atom({
  key: 'keptVesselSearchResults',
  default: [],
});

export const forceVesselSearchState = atom({
  key: 'forceVesselSearchState',
  default: false,
});

export const vesselValuationResults: RecoilState<any> = atom({
  key: 'vesselValuationResults',
  default: [],
});

export const vesselBudgetIndication: RecoilState<any> = atom({
  key: 'vesselBudgetIndication',
  default: [],
});

//#endregion
